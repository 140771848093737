import React from "react"
import { Link } from "gatsby";
import { Layout } from "../layouts/Site"
import { Helmet } from "react-helmet";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { faDirections } from "@fortawesome/pro-light-svg-icons";

export default function Page({location}) {
  return <Layout title='Xodiac'>
    <Helmet>
      <meta httpEquiv='refresh' content={`0;url=https://xodiac.ca`} />
    </Helmet>
    <div className='text-center h-full p-4 mt-20'>
      <h1><Icon icon={faDirections} /> We moved...</h1>
      You found our old home, which will soon be unavailable.<br />
      We will take you to <Link to='https://xodiac.ca'>https://xodiac.ca</Link> momentarily.
    </div>
  </Layout>
}